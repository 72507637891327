module.exports = {
    AppName: "工具API",
    AppSimpleName: "工具API",
    FooterText: "Ant Design ©2018 Created by Ant UED",
    HomePage: "/",
    UploadFileUrl: "https://img.hangzhoushuangchen.com/upload/files",
    LockPage: "/lock",
    CaptchaUrl: "/captcha",
    LoginPage: "https://admin.lsshenyan.com/",
    AuthUrl: "https://admin.lsshenyan.com/apps",
    InitMenu: false,
    USERINFO_KEY: "wx_userinfo",
    SECRET_KEY: "wx_secret",
    LOCAL_URL_KEY: "wx_local_url",
    BaseUrl: "https://dvx.lsshenyan.com/api",
}
